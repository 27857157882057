// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { urlSettings } from 'src/app/shared/http-confing/settings/urlSettings';
import { urlSettings } from '../app/shared/http-confing/settings/urlSettings';

export const environment = {
  production: false,
  // apiUrl: 'http://192.168.1.34/api:8080',
  apiUrl: 'https://backend.chgroupus.com/api',
//   firebase: {
//     apiKey: 'AIzaSyC0Qn35GGmFwlcIHeAdTL2_gpweBdNo5YI',
//     authDomain: 'elwarsha-21b07.firebaseapp.com',
//     databaseURL: 'https://elwarsha-21b07.firebaseio.com',
//     projectId: 'elwarsha-21b07',
//     storageBucket: 'elwarsha-21b07.appspot.com',
//     messagingSenderId: '401131053071',
//     appId: '1:401131053071:web:49b7547a1dbb1c6b56837a',
//     measurementId: 'G-957YHXNMM8',
//   },
};

urlSettings.Settings = {
  // apiHost: '192.168.1.34',
  // apiEndPoint: 'api',
  // apiProtocol: 'http',
  // apiPort: 8080,
  apiProtocol: 'https',
  apiHost: 'backend.chgroupus.com',
  apiEndPoint: 'api',
  language: 'en',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
