<input
  [ngClass]="{
    'is-invalid': checkErrors()
  }"
  [formControl]="control"
  [placeholder]="placeholder"
  [type]="type"
  class="form-control"
  placeholder="Current Password"
/>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkRequired() && !hideErrors"
>
  {{ "errors.required" }}
</label>
<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkPattern() && !hideErrors"
>
  {{ "errors.pattern" }}
</label>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkMinLength() && !hideErrors"
>
  {{ "errors.minlength" }} ({{
    this.control.errors?.minlength?.requiredLength
  }}
  / {{ this.control.errors?.minlength?.actualLength }})
</label>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkMaxLength() && !hideErrors"
>
  {{ "errors.maxlength" }} ({{
    this.control.errors?.maxlength?.requiredLength
  }}
  / {{ this.control.errors?.maxlength?.actualLength }})
</label>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkMin() && !hideErrors"
>
  {{ "errors.min" }} ({{ this.control.errors?.min?.min }} /
  {{ this.control.errors?.min?.actual }})
</label>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkMax() && !hideErrors"
>
  {{ "errors.max" }} ({{ this.control.errors?.max?.max }} /
  {{ this.control.errors?.max?.actual }})
</label>

<label
  class="error jquery-validation-error small form-text invalid-feedback"
  *ngIf="checkNotMatching() && !hideErrors"
>
  {{ "errors.notmatchingpassword" }}
</label>
