const LOG_IN = "admin/login";
const LOGOUT = "admin/logout";
const SLIDERS = "admin/home/sliders";
const BLOGS = "admin/home/blogs";
const SOCIAL_HUBS = "admin/home/social-hubs";
const INVESTMENTS = "admin/home/investments";
const SECTORS = "admin/home/sectors";
const COMPANIES = "admin/home/companies";
const WEBSITE_SOCIAL_LINKS = "admin/home/website-social-links";
const ABOUT = "admin/about/who-are-we";
const MAIN_PAGES = "admin/main-pages/page";
const MAIN_PAGES_SLIDERS = "admin/main-pages/sliders";
const MAIN_PAGES_CONTENT = "admin/main-pages/content";

const AUTH = "user/";
const CHANGE_PASSWORD = "change";
const MANUFACTURER = "manufacturer";
const AREAS = "area";
const COUNTRY = "country";
const SERVICE = "service";
const SPAREPARTS = "spare-part";
const MODEL = "model";
const MODEL_YEAR = "year";
const RAGIONS = "region";
const CUSTOMERS = "customer";
const OPERATION = "operation";
const BAN = "ban";
const Coupon = "coupon";
const SLOT = "slot";
const ORDER = "order";
const RECALCULATE = "recalculate";
const OREDR_HISTORY = "user-orders";
const COMPLAINT = "complaint";
const RESPOND = "respond";
const REVIEW = "review";
const CATALOGUE = "catalogue";
const PRODUCTS = "product";
const CAREER = "career";
const APPLICATION = "application";

export abstract class EndPoints {
  public static LOG_IN_ENDPOINT = LOG_IN;
  public static SLIDERS_ENDPOINT = SLIDERS;
  public static BLOGS_ENDPOINT = BLOGS;
  public static SOCIAL_HUBS_ENDPOINT = SOCIAL_HUBS;
  public static INVESTMENTS_ENDPOINT = INVESTMENTS;
  public static SECTORS_ENDPOINT = SECTORS;
  public static COMPANIES_ENDPOINT = COMPANIES;
  public static WEBSITE_SOCIAL_MEDIA_ENDPOINT = WEBSITE_SOCIAL_LINKS;
  public static ABOUT_ENDPOINT = ABOUT;
  public static MAIN_PAGES_ENDPOINT = MAIN_PAGES;
  public static MAIN_PAGES_CONTENT_ENDPOINT = MAIN_PAGES_CONTENT;
  public static MAIN_PAGES_SLIDERS_ENDPOINT = MAIN_PAGES_SLIDERS;

  public static CHANGE_PASSWORD_ENDPOINT = AUTH + CHANGE_PASSWORD;
  public static LOGOUT_ENDPOINT = LOGOUT;
  public static BAN_ENDPOINT = AUTH + BAN;
  public static MANUFACTURER_ENDPOINT = MANUFACTURER;
  public static RAGIONS_ENDPOINT = RAGIONS;
  public static MODEL_ENDPOINT = MODEL;
  public static MODEL_YEAR_ENDPOINT = MODEL_YEAR;
  public static CUSTOMERS_ENDPOINT = CUSTOMERS;
  public static AREAS_ENDPOINT = AREAS;
  public static COUNTRY_ENDPOINT = COUNTRY;
  public static SERVICE_ENDPOINT = SERVICE;
  public static OPERATION_ENDPOINT = OPERATION;
  public static COUPON_ENDPOINT = Coupon;
  public static SPAREPARTS_ENDPOINT = SPAREPARTS;
  public static SLOT_ENDPOINT = SLOT;
  public static ORDER_ENDPOINT = ORDER;
  public static RECALCULATE_ENDPOINT = `${ORDER}/${RECALCULATE}`;
  public static OREDR_HISTORY_ENDPOINT = `${ORDER}/${OREDR_HISTORY}`;
  public static COMPLAINT_ENDPOINT = COMPLAINT;
  public static RESPOND_ENDPOINT = RESPOND;
  public static REVIEW_ENDPOINT = REVIEW;
  public static CATALOGUE_ENDPOINT = CATALOGUE;
  public static PRODUCT_ENDPOINT = PRODUCTS;
  public static CAREER_ENDPOINT = CAREER;
  public static APPLICATION_ENDPOINT = APPLICATION;
}
