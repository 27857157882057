import { Component, forwardRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-upload-file',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UploadFileComponent),
			multi: true
		}
	]
})
export class UploadFileComponent implements ControlValueAccessor, OnInit {
	@ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;
	@Input() submitted: boolean ;
	@Input() formControlName: string;
	@Output() blur = new EventEmitter();

	focus = false;
	imgErr;
	img;
	userform;
	public dismissAlert(element) {
		element.parentElement.removeChild(element);
	}

	constructor(public controlContainer: ControlContainer) { }
	get control() {
		
		return this.controlContainer.control.get(this.formControlName);
	}
	ngOnInit() {
	}
	registerOnTouched(fn: any): void {

		this.formControlDirective.valueAccessor.registerOnTouched(fn);
	}

	registerOnChange(fn: any): void {
		this.formControlDirective.valueAccessor.registerOnChange(fn);
	}

	writeValue(obj: any): void {

		this.formControlDirective.valueAccessor.writeValue(obj);
		
	}

	setDisabledState(isDisabled: boolean): void {
		this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
	}




	onBlur(e) {
		const files = e.target.files;
		if (files.length === 0) {
			this.imgErr = false;
			return;
		}

		const mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.imgErr = true;
			return;
		}

		const reader = new FileReader();
		this.img = files;
		reader.readAsDataURL(files[0]);
		reader.onload = (_event) => {
			this.img = reader.result;
		};
		this.blur.emit(files[0]);
	}



}
