import { isPlatformBrowser } from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(private router: Router,@Inject(PLATFORM_ID) private platformId: any,
	public translate: TranslateService) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
		
		//initializing languages
		translate.addLangs(['en', 'ar']);

		// this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
 
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use('en');
   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }




  
}
