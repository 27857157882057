import { LoggedInGuard } from "./auth/shared/guards/logged-in.guard";
import { AuthComponent } from "./layout/auth/auth.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layout/admin/admin.component";
import { NotLoggedInGuard } from "./auth/shared/guards/not-logged-in.guard";
import { PageNotFoundComponent } from "./general/page-not-found/page-not-found.component";
// import { LoggedInGuard } from './auth/shared/guards';

const routes: Routes = [
  {
    path: "",
    canActivate: [NotLoggedInGuard],
    component: AdminComponent,
    // canActivate: [LoggedInGuard],
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./general/dashboard/dashboard.module').then(
      //       (module) => module.DashboardModule
      //     ),
      // },
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((module) => module.HomeModule),
      },
      {
        path: "about",
        loadChildren: () =>
          import("./about/about.module").then((m) => m.AboutModule),
      },
      {
        path: "model",
        loadChildren: () =>
          import("./our-model/our-model.module").then((m) => m.OurModelModule),
      },
      {
        path: "business-lines",
        loadChildren: () =>
          import("./business-lines/business-lines.module").then((m) => m.BusinessLinesModule),
      },
      {
        path: "partners",
        loadChildren: () =>
          import("./partners/partners.module").then((m) => m.PartnersModule),
      },
    ],
  },
  {
    path: "",
    component: AuthComponent,
    // canActivate: [LoggedInGuard],
    children: [
      {
        path: "user",
        loadChildren: () =>
          import("./auth/auth.module").then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },

  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
