<div class="auth-wrapper maintenance">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="text-center">
          <img
            src="assets/images/maintenance/404.png"
            alt=""
            class="img-fluid"
          />
          <h5 class="text-muted my-4">Oops! Page not found!</h5>
          <a
            routerLink="/"
            class="btn waves-effect text-white waves-light btn-primary mb-4"
            ><i class="feather icon-refresh-ccw mr-2"></i>Reload</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
