import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  @Input() Id;
  @Input() reviews:[];
  @Input() reviewsOrder;
  constructor() { }
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  ngOnInit(): void {
    console.log(this.reviews);
    
  }
  onImgError(event) {
    event.target.src = 'assets/images/user/avatar-1.jpg';
  }
}

