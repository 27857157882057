<div class="row">
  <div class="col-md-12 form-group">
    <div *ngIf='img' class='col-md-auto my-auto'>
      <img [alt]='img' [src]='img' class='img-fluid' style='max-width: 128px; max-height: 128px'/>
    </div>
    <div class="custom-file">
      <input
        type="file"
        [formControl]="control"
        class="custom-file-input"
        required
        [ngClass]="{
          'is-invalid': control?.invalid && (control?.touched || submitted)
        }"
        (change)=" onBlur($event)"
      />
      <label class="custom-file-label">Choose image...</label>
      <div *ngIf=" control?.invalid && (control?.touched || submitted)" class="invalid-feedback">
        Example invalid custom file feedback
      </div>
    </div>
  </div>
</div>


