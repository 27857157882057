import { Injectable } from "@angular/core";

export interface NavigationItem {
  id: string;
  title: string;
  type: "item" | "collapse" | "group";
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: "navigation",
    title: "navigation.navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "home",
        title: "Home",
        type: "collapse",
        icon: "feather icon-home",
        children: [
          {
            id: "sliderImages",
            title: "Slider Images",
            type: "item",
            icon: "feather icon-image",
            url: "/home/slider-images",
          },
          {
            id: "blogs",
            title: "Blogs",
            type: "item",
            icon: "feather icon-monitor",
            url: "/home/blogs",
          },
          {
            id: "publicInvestments",
            title: "Public Investments",
            type: "item",
            icon: "feather icon-trending-up",
            url: "/home/investments",
          },
          {
            id: "sectors",
            title: "Sectors",
            type: "item",
            icon: "feather icon-layers",
            url: "/home/sectors",
          },
          {
            id: "companies",
            title: "Companies",
            type: "item",
            icon: "feather icon-briefcase",
            url: "/home/companies",
          },
          {
            id: "socialHubs",
            title: "Social Hubs",
            type: "item",
            icon: "feather icon-feather",
            url: "/home/social-hubs",
          },
        ],
      },
      {
        id: "countries",
        title: "Countries",
        type: "item",
        icon: "feather icon-globe",
        url: "/home/countries",
      },
      {
        id: "cities",
        title: "Cities",
        type: "item",
        icon: "feather icon-globe",
        url: "/home/cities",
      },
      {
        id: "about",
        title: "About",
        type: "collapse",
        icon: "feather icon-info",
        children: [
          {
            id: "content",
            title: "Header",
            type: "item",
            url: "/about/content",
          },
          {
            id: "objective",
            title: "Objective",
            type: "item",
            url: "/about/objective",
          },
          {
            id: "vision",
            title: "Vision",
            type: "item",
            url: "/about/vision",
          },
          {
            id: "mission",
            title: "Mission",
            type: "item",
            url: "/about/mission",
          },
          {
            id: "history",
            title: "History",
            type: "item",
            url: "/about/history",
          },
          {
            id: "management",
            title: "Management",
            type: "item",
            url: "/about/management",
          },
          {
            id: "strategic-targets",
            title: "Strategic Targets",
            type: "item",
            url: "/about/strategicTargets",
          },
          {
            id: "terms-and-conditions",
            title: "Terms And Conditions",
            type: "item",
            url: "/about/terms-and-conditions/21",
          },
          {
            id: "privacy",
            title: "Privacy",
            type: "item",
            url: "/about/privacy/22",
          },
          {
            id: "social-media",
            title: "Social Media",
            type: "item",
            url: "/about/social-media",
          },
        ],
      },
      {
        id: "our-model",
        title: "Our Model",
        type: "collapse",
        icon: "feather icon-command",
        children: [
          {
            id: "header",
            title: "Header",
            type: "item",
            icon: "feather icon-layout",
            url: "/model/header/4",
          },
          {
            id: "our-investments",
            title: "Our Investments",
            type: "item",
            url: "/model/our-investments/5",
          },
          {
            id: "giga-projects",
            title: "Giga Projects",
            type: "item",
            url: "/model/giga-projects/6",
          },
          {
            id: "global-investments",
            title: "Global Investments",
            type: "item",
            url: "/model/global-investments/7",
          },
        ],
      },
      {
        id: "business-lines",
        title: "Business Lines",
        type: "collapse",
        icon: "feather icon-bar-chart-2",
        children: [
          {
            id: "header",
            title: "Header",
            type: "item",
            icon: "feather icon-layout",
            url: "/business-lines/8/header",
          },
          {
            id: "default-content",
            title: "Default Content",
            type: "item",
            url: "/business-lines/9/default-content",
          },
          {
            id: "halal-certification",
            title: "Halal Certification",
            type: "item",
            url: "/business-lines/10/halal-certification",
          },
          {
            id: "kosher-certification",
            title: "Kosher Certification",
            type: "item",
            url: "/business-lines/24/kosher-certification",
          },
          {
            id: "global-funds",
            title: "Global Funds",
            type: "item",
            url: "/business-lines/11/global-funds",
          },
          {
            id: "global-commodity",
            title: "Global Commodity",
            type: "item",
            url: "/business-lines/12/global-commodity",
          },
          {
            id: "manufacturing",
            title: "Manufacturing",
            type: "item",
            url: "/business-lines/13/manufacturing",
          },
          {
            id: "technology-solutions",
            title: "Technology Solutions",
            type: "item",
            url: "/business-lines/14/technology-solutions",
          },
          {
            id: "giga-projects",
            title: "Giga Projects",
            type: "item",
            url: "/business-lines/15/giga-projects",
          },
          {
            id: "real-estate",
            title: "Real Estate",
            type: "item",
            url: "/business-lines/16/real-estate",
          },
        ],
      },
      {
        id: "partners",
        title: "Partners",
        type: "collapse",
        icon: "feather icon-users",
        children: [
          {
            id: "global-partners",
            title: "Global Partners",
            type: "item",
            url: "/partners/global-partners/19",
          },
          {
            id: "local-partners",
            title: "Local Partners",
            type: "item",
            url: "/partners/local-partners/20",
          },
        ],
      },
      // {
      //   id: "contacts",
      //   title: "Contacts",
      //   type: "item",
      //   icon: "feather icon-phone",
      //   url: "/dashboard/contact",
      // },
    ],
  },
];
@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
